import React, { useState, useContext, useEffect } from 'react';
import { StaticQuery, useStaticQuery, graphql, Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';
import Loadable from 'react-loadable';
import { Container, Block, Loading } from '../primitives';

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        pages {
          label
          link
        }
      }
    }
    staff: allSanityStaff(sort: { fields: order, order: ASC }) {
      edges {
        ...Staff
      }
    }
  }
`;

const Section = styled.section.attrs({ className: 'relative bg-white' })`
  max-width: 600px;
  margin: 0 auto;
  h2 {
    font-size: 1.125rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  hr {
    border: 1px #ffd700 solid;
    margin: 2rem 0;
  }
`;

const LinkWrapper = styled.div`
  ul {
    list-style-type: square;
    list-style-position: inside;
  }
  li {
    margin-bottom: 0.5rem;
  }
  a {
    display: inline-block;
    color: #1a202c;
    font-size: 0.875rem;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    border-bottom: 2px #ffffff solid;
    :hover {
      color: #000000;
      border-bottom: 2px #2a4365 solid;
    }
  }
`;

const Sitemap = () => {
  //

  const {
    site: {
      siteMetadata: { pages },
    },
    staff: { edges: allStaff },
  } = useStaticQuery(QUERY);

  return (
    <Section>
      <Container>
        <Block>
          <h2>Pages</h2>
          <LinkWrapper>
            <ul>
              {pages.map(page => (
                <li key={page.link}>
                  <Link to={page.link}>{page.label}</Link>
                </li>
              ))}
              <li>
                <Link to="/career">Jobs at SDAA</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Notice</Link>
              </li>
              <li>
                <Link to="/terms">Terms of Use</Link>
              </li>
              <li>
                <Link to="/cookies">Cookie Notice</Link>
              </li>
            </ul>
          </LinkWrapper>
          <hr />
          <h2>Coaches</h2>
          <LinkWrapper>
            <ul>
              {allStaff.map(staff => (
                <li key={staff.node.id}>
                  <Link to={`/about#${staff.node.id}`}>
                    {staff.node.name}
                    <div>{staff.node.title}</div>
                  </Link>
                </li>
              ))}
            </ul>
          </LinkWrapper>
        </Block>
      </Container>
    </Section>
  );
};

export default Sitemap;

Sitemap.propTypes = {
  //
};

Sitemap.defaultProps = {
  //
};
