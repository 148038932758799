import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';
import { SEO } from '../components/primitives';
import Main from '../components/_sitemap/Sitemap';

const pageTransition = {
  initial: {},
  enter: {},
  exit: {},
};

const Sitemap = ({
  data: {
    site: {
      siteMetadata: { siteName },
    },
  },
  location: { pathname },
}) => {
  //

  return (
    <motion.article
      className="relative"
      key={pathname}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={pageTransition}
    >
      <SEO title="Site Map" description={`Complete collection of links to ${siteName} site.`} />
      <Main />
    </motion.article>
  );
};

export default Sitemap;

Sitemap.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        siteName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteName
      }
    }
  }
`;
